/* App container */

body {
  background: linear-gradient(to bottom right, #3F51B5, #7C3EFE);
  margin: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0 auto;
}

.single-column {
  margin: 0 auto;
  overflow-y: auto;
}

/* Split container */
.split {
  display: flex;
  margin: 0 auto;
  max-width: 812px;
  min-width: 512px;
  overflow-y: auto;
  overflow-x: hidden;

}

/* Left column */
.left-column {
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
}

/* Left column image */
.App__img {
  max-height: 656px;
  object-fit: contain;
}

/* Right column */
.right-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
}

/* Title */
.App__title {
  color: #fff;
  font-size: 3rem;
  margin-bottom: 0;
}

/* Subtitle */
.App__subtitle {
  color: #fff;
  font-size: 1.5rem;
  margin-top: 15;
  text-align: center;
}

/* List */
.App__list {
  flex: 1;
  color: #fff;
  font-size: 1.25rem;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* List items */
.App__list li {
  margin: 15px;
}

/* Buttons container */
.App__buttons {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Button */
.App__button {
  margin: 0 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Icon */
.App__icon {
  height: 70px;
  max-width: 456px;
}

/* Policy */
.App__policy {
  color: #fff;
  font-size: 1rem;
  margin-top: 1rem;
  text-align: center;
}

/* Policy link */
.App__policy a {
  color: #fff;
  text-decoration: underline;
}